<template>
  <div class="container">
    <h1>

      Terms of Use
    </h1>
    <p>
      These Terms of Use (this "Agreement") is a legal agreement between you ("you") and Lighter Life LTD ("Company,"
      "we" or "us") for use of the Litely mobile application, the website (litely.life), the servers used by the
      application, the computer files stored on such servers, and all related services, features and content offered by
      the Company (collectively, the "App").
    </p>
    <h2>

      1. Acceptance of terms
    </h2>
    <p>
      Please read this Agreement carefully. By creating an account or accessing or using the App, you acknowledge that
      you accept and agree to be bound by the terms of this Agreement. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT
      ACCESS OR USE THE APP.
    </p>
    <p>
      We may modify this Agreement from time to time. We will notify you by email, through the App, or by presenting you
      with a new version of the Agreement for you to accept if we make modifications that materially change your rights.
      Your continued use of the App after the effective date of an updated version of the Agreement will indicate your
      acceptance of the Agreement as modified.
    </p>
    <h2>

      2. Medical services disclaimer
    </h2>
    <p>
      The company is not a licensed medical care provider. The app and website are not intended to replace professional
      medical advice or diagnose, treat or manage any illness or medical condition. No physician-patient relationship is
      created by this app/website or its use.
    </p>
    <p>

      Although we do our best to provide reliable and informative material, we cannot and do not make any
      representations or warranty with respect to the content on our site and app. Use of our site, app, and other
      services is at your own risk. Inform your physician of any changes you may make to your lifestyle.
    </p>
    <p>

      The information, including but not limited to, text, graphics, images and other material contained on this app and
      website are for informational purposes only. No material on this app and website is intended to be a substitute
      for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other
      qualified healthcare providers with any questions you may have regarding a medical condition or treatment and
      before undertaking a new health care regimen, and never disregard professional medical advice or delay in seeking
      it because of something you have read on this website, app or our other communication channels.
    </p>
    <p>

      You should not do Intermittent Fasting:
    </p>
    <p>

      -If you are a child under 18.
    </p>
    <p>
      -If you are pregnant.
    </p>
    <p>
      -If you are breastfeeding.
    </p>
    <p>
      -If you have an eating disorder.
    </p>
    <p>
      -If you have health conditions.
    </p>
    <p>
      -If you take prescription medication.
    </p>
    <p>
      -If you are underweight (BMI &lt; 18.5). </p>

    <p>
      If you think you have a medical emergency, call 911 or go to the nearest open emergency room immediately.
    </p>

    <h2>

      3. Registration and eligibility
    </h2>
    <p>
      To use the App, you may be required to create or update an account ("Account") and will be asked to provide
      certain personal information, which may include your name, gender, birth date, and e-mail address. This
      information will be held and used in accordance with our privacy policy, which can be found at
      <a href="https://www.litely.life/privacy/" target="_blank">https://www.litely.life/privacy/</a> ("Privacy
      Policy"). You agree that
      you will supply accurate and complete
      information to the Company, and that you will update that information promptly after it changes.
    </p>
    <p>
      To create an Account and access the App, you must be at least 18 years old and not barred from using the App
      under applicable law.
    </p>
    <h2>

      4. Your use of the App
    </h2>
    <p>
      Any content you submit through the App is governed by the Company's Privacy Policy
      <a href="https://www.litely.life/privacy/" target="_blank">https://www.litely.life/privacy/</a>. To the extent there is an
      inconsistency between this Agreement and the
      Company's Privacy Policy, these Terms shall govern. If you submit a question or response, you are solely
      responsible for your own communications, the consequences of posting those communications, and your reliance
      on any communications found in the public areas. The Company and its licensors are not responsible for the
      consequences of any communications in the public areas. In cases where you feel threatened or you believe
      someone else is in danger, you should contact your local law enforcement agency immediately. If you think you
      may have a medical emergency, call your doctor or 911 immediately. As a condition of using the App, you agree
      not to use the App for any purpose that is prohibited by this Agreement. You are responsible for all of your
      activity in connection with the App and you shall abide by all local, state, national, and international laws
      and regulations and any applicable regulatory codes. You agree that if you take any of the following actions,
      you will be materially breaching this Agreement, and you agree that you SHALL NOT:
    </p>
    <p>
      a. resell, rent, lease, loan, sublicense, distribute, or otherwise transfer rights to the App;
    </p>
    <p>
      b. modify, reverse engineer, decompile or disassemble the App;
    </p>
    <p>
      c. copy, adapt, alter, modify, translate, or create derivative works of the App without written authorization
      of the Company;
    </p>
    <p>
      d. permit other individuals to use the App, including but not limited to shared use via a network connection,
      except under the terms of this Agreement;
    </p>
    <p>
      e. circumvent or disable any technological features or measures in the App for protection of intellectual
      property rights;
    </p>
    <p>
      f. use the App in an attempt to, or in conjunction with, any device, program, or service designed to
      circumvent technological measures employed to control access to, or the rights in, a content file or other
      work protected by the copyright laws of any jurisdiction;
    </p>
    <p>
      g. use or access the App to compile data in a manner that is used or usable by a competitive product or
      service;
    </p>
    <p>
      h. use your Account to advertise, solicit, or transmit any commercial advertisements, including chain letters,
      junk e-mail or repetitive messages to anyone;
    </p>
    <p>
      i. use your Account to engage in any illegal conduct;
    </p>
    <p>
      j. upload to transmit any communications that infringe or violate the rights of any party;
    </p>
    <p>
      k. upload media of any kind that contain expressions of hate, abuse, offensive images or conduct, obscenity,
      pornography, sexually explicit or any material that could give rise to any civil or criminal liability under
      applicable law or regulations or that otherwise may be in conflict with this Agreement and the Company's
      Privacy Policy; or
    </p>
    <p>
      l. upload any material that contains software viruses or any other computer code, files or programs designed
      to interrupt, destroy or limit the functionality of any computer software or this website.
    </p>
    <p>
      Any such forbidden use shall immediately terminate your license to use the App.
    </p>
    <h2>

      5. Children's privacy
    </h2>
    <p>
      We are committed to protecting the privacy of children. You should be aware that this App is not intended or
      designed to attract children under the age of 18. We do not collect personally identifiable information from
      any person we actually know is a child under the age of 18.
    </p>
    <h2>

      6. Limited license to the App
    </h2>
    <p>
      We grant you a personal, worldwide, revocable, non-transferable and non-exclusive license to access and use
      the App for personal and non-commercial purposes in accordance with the terms of this Agreement.
    </p>
    <p>
      All rights, title, and interest in and to the App not expressly granted in this Agreement are reserved by the
      Company. If you wish to use the Company's software, title, trade name, trademark, service mark, logo, domain
      name and/or any other identification with notable brand features or other content owned by the Company, you
      must obtain written permission from the Company. Permission requests may be sent to 
     support@mail.litely.life.
    </p>
    <h2>

      7. License to User Content
    </h2>
    <p>
      The App enables you to input personal notes, share your stories, and log certain information into the App
      ("User Content"). You retain all rights to such User Content that you post, share or log in the App.
    </p>
    <p>
      By providing your User Content to the App, you (a) grant the Company a non-exclusive, transferable,
      sublicensable, worldwide, royalty-free license to use, copy, exploit, modify, publicly display, publicly
      perform, create derivative works from, incorporate it into other works, change, reformat, and distribute your
      User Content in connection with providing and operating the App and related services and/or for the Company's
      promotional purposes (for example, by displaying on our website, within the App, in social media, on any
      website or platform in the internet as we may deem appropriate), subject to the Privacy Policy; and (b) you
      agree to indemnify the Company and its affiliates, directors, officers, and employees and hold them harmless
      from any and all claims and expenses, including attorneys' fees, arising from the media and/or your failure to
      company with the terms described in this Agreement.
    </p>
    <p>
      The Company reserves the right to review all User Content prior to submission to the site and to remove any
      media for any reason, at any time, without prior notice, at our sole discretion.
    </p>
    <h2>

      8. Use at your own risk
    </h2>
    <p>
      Our goal is to help make certain health-related information more readily available and useful to you, however,
      the App cannot and does not guarantee health-related improvements or outcomes. Your use of the App and any
      information, predictions, or suggestions provided in the App are at your sole risk. We make no representation
      or warranty of any kind as to the accuracy of data, information, estimates, and predictions that we may
      provide to you through the App and you agree and understand that the App is not intended to match or serve the
      same purpose as a medical or scientific device.
    </p>
    <h2>

      9. Subscriptions
    </h2>
    <p>
      Litely subscription. We offer subscriptions that grant you access to the App's special features and content,
      including, but not limited to, personalized insights based on your behavior and eating patterns, tools for
      tracking your meals, fasts, water intakes, body measurements and physical activity. You agree that your
      purchases are not contingent on the delivery of any future functionality or features, or dependent on any oral
      or written public comments made by Litely regarding future functionality or features.
    </p>
    <p>

      Billing. You may purchase Litely subscription through the mobile application. If you choose to purchase the
      subscription via the application, payment will be charged to your credit/debit card through your iTunes
      Account or Google Play account after you choose one of our subscriptions and confirm your purchase.
    </p>
    <p>

      Renewal and Cancellation. Your paid subscription will automatically renew at the end of the applicable
      subscription period, unless auto-renew is turned off or unless you cancel your subscription no later than 24
      hours before the end of the subscription period. You can cancel your subscription any time in the Manage
      Subscriptions section of your account settings. Should you have any concerns, please contact our support team
      at support@mail.litely.life for instructions on how to cancel.
    </p>
    <p>
      Sometimes we also offer refunds: e.g., in case of the statutory cooling-off period, or in case you don’t get
      visible results with the program and upon meeting certain criteria. . Please note that some items are
      non-refundable (e.g., lifetime subscription, gift card purchases).
    </p>
    <p>
      Please be aware that your purchased subscription does not cancel automatically if you delete the App. You
      should cancel the subscription before deletion of the App.
    </p>
    <p>
      Changes. We may from time to time make changes to our subscription options, including recurring subscription
      fees and will communicate any price changes to you in advance. Price changes will take effect at the start of
      the next subscription period following the date of the price change and, by continuing to use our subscription
      after the price change takes effect, you will have accepted the new price. If you don’t agree to a price
      change, you can reject the change by unsubscribing from the applicable subscription no later than 24 hours
      before the end of the subscription term. Tax rates or other fees are based on the rates applicable at the time
      of your monthly charge. These amounts can change over time with local tax requirements in your country, state,
      territory, county, or city. Any change in tax rate will be automatically applied based on the account
      information you provide.
    </p>
    <h2>

      10. Account security
    </h2>
    <p>
      You are responsible for taking all reasonable steps to ensure that no unauthorized person shall have access to
      your App's account. It is your sole responsibility to (1) control the dissemination and use of sign-in name,
      screen name and passwords; (2) authorize, monitory, and control access to and use of your App account; (3)
      promptly inform Litely if you believe your account has been compromised or if there is any other reason you
      need to deactivate your account. Send us an email at support@mail.litely.life. You grant Litely and all other
      persons or entities involved in the operation of the App the right to transmit, monitor, retrieve, store, and
      use your information in connection with the operation of the App. Litely cannot and does not assume any
      responsibility or liability for any information you submit, or your or third parties’ use or misuse of
      information transmitted or received using the App, and shall not be responsible for any losses arising out of
      the unauthorised use of your account or information resulting from you not following these rules.
    </p>
    <h2>

      11. Warranty disclaimer
    </h2>
    <p>
      The Company controls and operates the App from various locations and makes no representation that the App is
      appropriate or available for use in all locations. The App or certain features of it may not be available in
      your location or may vary across locations.
    </p>
    <p>
      The app is provided "as is", "as available" and is provided without any representations or warranties of any
      kind, express or implied, including, but not limited to, the implied warranties of title, non-infringement,
      merchantability and fitness for a particular purpose, and any warranties implied by any course of performance
      or usage of trade, all of which are expressly disclaimed, save to the extent required by law. The company, and
      its directors, employees, agents, representatives, suppliers, partners and content providers do not warrant
      that: (a) the app will be secure or available at any particular time or location; (b) any defects or errors
      will be corrected; (c) any content or software available at or through the app is free of viruses or other
      harmful components; or (d) the results of using the app will meet your requirements. Your use of the app is
      solely at your own risk. Some states / countries do not allow limitations on implied warranties, so the above
      limitations may not apply to you.
    </p>
    <h2>

      12. Limitation of liability
    </h2>
    <p>
      In no event shall the company, its officers, directors, agents, affiliates, employees, advertisers, or data
      providers be liable for any indirect, special, incidental, consequential or punitive damages (including but
      not limited to loss of use, loss of profits, or loss of data) whether in an action in contract, tort
      (including but not limited to negligence), equity or otherwise, arising out of or in any way connected with
      the use of this app. In no event will the company's total liability arising out of or in connection with these
      terms or from the use of or inability to use the app exceed the amounts you have paid to the company for use
      of the app or one hundred dollars ($100) if you have not had any payment obligations to the company, as
      applicable. some jurisdictions do not allow the exclusion or limitation of liability, so the above limitations
      may not apply to you. the company, or any third parties mentioned on the app are not liable for any personal
      injury, including death, caused by your use or misuse of the app.
    </p>
    <h2>

      13. Use of mobile devices
    </h2>
    <p>
      Please note that your carrier's normal rates and fees, such as text messaging and data charges, will still
      apply if you are using the App on a mobile device.
    </p>
    <h2>

      14. Third Party Services
    </h2>
    <p>
      The App may give you access to links to third-party websites, apps, or other products or services ("Third
      Party Services"). The Company does not control Third Party Services in any manner and, accordingly, does not
      assume any liability associated with such Third Party Services. You need to take appropriate steps to
      determine whether accessing a Third Party Service is appropriate, and to protect your personal information and
      privacy in using any such Third Party Services.
    </p>
    <h2>

      15. Your feedback
    </h2>
    <p>
      We welcome your feedback about the App. Unless otherwise expressly declared, any communications you send to us
      are deemed to be submitted on a non-confidential basis. You agree that we may decide to publicize such
      contents at our own discretion. You agree to authorize us to make use of such contents for free, and revise,
      modify, adjust and change contextually, or make any other changes as we deem appropriate.
    </p>
    <h2>

      16. Enforcement rights
    </h2>
    <p>
      We are not obligated to monitor access or use of the App, however, we reserve the right to do so for purposes
      of operating and maintaining the App, ensuring your compliance with this Agreement, and complying with
      applicable legal requirements. We may disclose unlawful conduct to law enforcement authorities; and pursuant
      to valid legal process, we may cooperate with law enforcement authorities to prosecute users who violate the
      law. We reserve the right (but are not required) to remove or disable any content posted to the App or access
      to App at any time and without notice, and at our sole discretion, if we determine in our sole discretion that
      your content or use of the App is objectionable or in violation this Agreement.
    </p>
    <p>
      The Company has no liability or responsibility to users of the App or any other person or entity for
      performance or nonperformance of the aforementioned activities.
    </p>
    <h2>

      17. Changes to the App
    </h2>
    <p>
      From time to time and without prior notice to you, we may change, expand and improve the App. We may also, at
      any time, cease to continue operating part or all of the App or selectively disable certain features of the
      App. Your use of the App does not entitle you to the continued provision or availability of the App. Any
      modification or elimination of the App or any particular features will be done in our sole and absolute
      discretion and without an ongoing obligation or liability to you.
    </p>
    <h2>

      18. Indemnity
    </h2>
    <p>
      You agree to defend, indemnify, and hold the Company, its officers, directors, employees, agents, licensors,
      and suppliers, harmless from and against any claims, actions or demands, liabilities and settlements including
      without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your
      violation of this Agreement.
    </p>
    <h2>

      19. Miscellaneous
    </h2>
    <p>
      Any dispute arising from this Agreement shall be governed by the laws of Hong Kong without regard to its
      conflict of law provisions. SOLE AND EXCLUSIVE JURISDICTION FOR ANY ACTION OR PROCEEDING ARISING OUT OF OR
      RELATED TO THIS AGREEMENT SHALL BE IN THE STATE OF DELAWARE AND THE PARTIES UNCONDITIONALLY WAIVE THEIR
      RESPECTIVE RIGHTS TO A JURY TRIAL.
    </p>
    <p>
      Any cause of action you may have with respect to your use of the App must be commenced within one (1) year
      after the claim or cause of action arises.
    </p>
    <p>
      If for any reason a court of competent jurisdiction finds any provision of this Agreement, or a portion
      thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to
      affect the intent of this Agreement, and the remainder of this Agreement shall continue in full force and
      effect. A printed version of this Agreement shall be admissible in judicial or administrative proceedings.
    </p>
    <p>
      No waiver of by the Company of any term or condition set forth in this Agreement shall be deemed a further or
      continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the
      Company to assert a right or provision under this Agreement shall not constitute a waiver of such right or
      provision. If any provision of this Agreement is held by a court or other tribunal of competent jurisdiction
      to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the
      minimum extent such that the remaining provisions of this Agreement will continue in full force and effect.
    </p>
    <p>
      Upon termination, all provisions of this Agreement, which, by their nature, should survive termination, shall
      survive termination, including, without limitation, ownership provisions, warranty disclaimers and limitations
      of liability.
    </p>
    <p>
      All claims between the parties related to this Agreement will be litigated individually and the parties will
      not consolidate or seek class treatment for any claim, unless previously agreed to in writing by the parties.
    </p>
    <p>
      We may refuse service, close Accounts, and change eligibility requirements at any time.
    </p>
    <h2>

      20. Notice and takedown procedures
    </h2>
    <p>
      If you believe any materials accessible on or from the App infringe your copyright, you may request removal of
      those materials (or access thereto) from this App by contacting the Company and providing the following
      information:
    </p>
    <p>
      a. Identification of the copyrighted work that you believe to be infringed. Please describe the work, and
      where possible include a copy or the location (e.g., App page) of an authorized version of the work.
    </p>
    <p>
      b. Identification of the material that you believe to be infringing and its location. Please describe the
      material, and provide us with its URL or any other pertinent information that will allow us to locate the
      material.
    </p>
    <p>
      c. Your name, address, telephone number and (if available) e-mail address.
    </p>
    <p>
      d. A statement that you have a good faith belief that the use of the materials in the manner complained of is
      not authorized by the copyright owner, its agent, or the law.
    </p>
    <p>
      e. A statement that the information that you have supplied is accurate, and indicating that "under penalty of
      perjury," you are the copyright owner or are authorized to act on the copyright owner's behalf.
    </p>
    <p>
      f. A signature or the electronic equivalent from the copyright holder or authorized representative.
    </p>
    <p>
      Questions and comments
    </p>
    <p>
      If you have any comments or questions on any part of the services or any part of these Terms of Use, please
      feel free to contact us at support@mail.litely.life
    </p>





























    <!-- <h1>Terms and Conditions</h1>
        <p>Last updated: April 08, 2022</p>
        <p>
          Please read these terms and conditions carefully before using Our Service.
        </p>
        <h1>Interpretation and Definitions</h1>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings defined
          under the following conditions. The following definitions shall have the
          same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of these Terms and Conditions:</p>
        <ul>
          <li>
            <p>
              <strong>Application</strong> means the software program provided by
              the Company downloaded by You on any electronic device, named Litely
            </p>
          </li>
          <li>
            <p>
              <strong>Application Store</strong> means the digital distribution
              service operated and developed by Apple Inc. (Apple App Store) or
              Google Inc. (Google Play Store) in which the Application has been
              downloaded.
            </p>
          </li>
          <li>
            <p>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election of
              directors or other managing authority.
            </p>
          </li>
          <li>
            <p><strong>Country</strong> refers to: Hong Kong SAR China</p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
              this Agreement) refers to Lighter Life Limited, Room 1902, Yishi
              commercial building, 253-261 Hennessy Road, Wanchai, Hong Kong.
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the Service
              such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Application or the Website or
              both.
            </p>
          </li>
          <li>
            <p>
              <strong>Terms and Conditions</strong> (also referred as
              &quot;Terms&quot;) mean these Terms and Conditions that form the
              entire agreement between You and the Company regarding the use of the
              Service. This Terms and Conditions agreement has been created with the
              help of the
              <a href="https://www.freeprivacypolicy.com/blog/sample-terms-conditions-template/" target="_blank">Terms
                and
                Conditions Template</a>.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-party Social Media Service</strong> means any services
              or content (including data, information, products or services)
              provided by a third-party that may be displayed, included or made
              available by the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Website</strong> refers to Litely, accessible from
              <a href="https://www.litely.life" rel="external nofollow noopener"
                target="_blank">https://www.litely.life</a>
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <h1>Acknowledgment</h1>
        <p>
          These are the Terms and Conditions governing the use of this Service and
          the agreement that operates between You and the Company. These Terms and
          Conditions set out the rights and obligations of all users regarding the
          use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your acceptance of
          and compliance with these Terms and Conditions. These Terms and Conditions
          apply to all visitors, users and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service You agree to be bound by these Terms and
          Conditions. If You disagree with any part of these Terms and Conditions
          then You may not access the Service.
        </p>
        <p>
          You represent that you are over the age of 18. The Company does not permit
          those under 18 to use the Service.
        </p>
        <p>
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company. Our
          Privacy Policy describes Our policies and procedures on the collection,
          use and disclosure of Your personal information when You use the
          Application or the Website and tells You about Your privacy rights and how
          the law protects You. Please read Our Privacy Policy carefully before
          using Our Service.
        </p>
        <h1>Links to Other Websites</h1>
        <p>
          Our Service may contain links to third-party web sites or services that
          are not owned or controlled by the Company.
        </p>
        <p>
          The Company has no control over, and assumes no responsibility for, the
          content, privacy policies, or practices of any third party web sites or
          services. You further acknowledge and agree that the Company shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods or services available on or through
          any such web sites or services.
        </p>
        <p>
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party web sites or services that You visit.
        </p>
        <h1>Termination</h1>
        <p>
          We may terminate or suspend Your access immediately, without prior notice
          or liability, for any reason whatsoever, including without limitation if
          You breach these Terms and Conditions.
        </p>
        <p>
          Upon termination, Your right to use the Service will cease immediately.
        </p>
        <h1>Limitation of Liability</h1>
        <p>
          Notwithstanding any damages that You might incur, the entire liability of
          the Company and any of its suppliers under any provision of this Terms and
          Your exclusive remedy for all of the foregoing shall be limited to the
          amount actually paid by You through the Service or 100 USD if You haven't
          purchased anything through the Service.
        </p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall the
          Company or its suppliers be liable for any special, incidental, indirect,
          or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information, for
          business interruption, for personal injury, loss of privacy arising out of
          or in any way related to the use of or inability to use the Service,
          third-party software and/or third-party hardware used with the Service, or
          otherwise in connection with any provision of this Terms), even if the
          Company or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose.
        </p>
        <p>
          Some states do not allow the exclusion of implied warranties or limitation
          of liability for incidental or consequential damages, which means that
          some of the above limitations may not apply. In these states, each party's
          liability will be limited to the greatest extent permitted by law.
        </p>
        <h1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
        <p>
          The Service is provided to You &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of any
          kind. To the maximum extent permitted under applicable law, the Company,
          on its own behalf and on behalf of its Affiliates and its and their
          respective licensors and service providers, expressly disclaims all
          warranties, whether express, implied, statutory or otherwise, with respect
          to the Service, including all implied warranties of merchantability,
          fitness for a particular purpose, title and non-infringement, and
          warranties that may arise out of course of dealing, course of performance,
          usage or trade practice. Without limitation to the foregoing, the Company
          provides no warranty or undertaking, and makes no representation of any
          kind that the Service will meet Your requirements, achieve any intended
          results, be compatible or work with any other software, applications,
          systems or services, operate without interruption, meet any performance or
          reliability standards or be error free or that any errors or defects can
          or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither the Company nor any of the
          company's provider makes any representation or warranty of any kind,
          express or implied: (i) as to the operation or availability of the
          Service, or the information, content, and materials or products included
          thereon; (ii) that the Service will be uninterrupted or error-free; (iii)
          as to the accuracy, reliability, or currency of any information or content
          provided through the Service; or (iv) that the Service, its servers, the
          content, or e-mails sent from or on behalf of the Company are free of
          viruses, scripts, trojan horses, worms, malware, timebombs or other
          harmful components.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a consumer, so
          some or all of the above exclusions and limitations may not apply to You.
          But in such a case the exclusions and limitations set forth in this
          section shall be applied to the greatest extent enforceable under
          applicable law.
        </p>
        <h1>Governing Law</h1>
        <p>
          The laws of the Country, excluding its conflicts of law rules, shall
          govern this Terms and Your use of the Service. Your use of the Application
          may also be subject to other local, state, national, or international
          laws.
        </p>
        <h1>Disputes Resolution</h1>
        <p>
          If You have any concern or dispute about the Service, You agree to first
          try to resolve the dispute informally by contacting the Company.
        </p>
        <h1>For European Union (EU) Users</h1>
        <p>
          If You are a European Union consumer, you will benefit from any mandatory
          provisions of the law of the country in which you are resident in.
        </p>
        <h1>United States Legal Compliance</h1>
        <p>
          You represent and warrant that (i) You are not located in a country that
          is subject to the United States government embargo, or that has been
          designated by the United States government as a &quot;terrorist
          supporting&quot; country, and (ii) You are not listed on any United States
          government list of prohibited or restricted parties.
        </p>
        <h1>Severability and Waiver</h1>
        <h2>Severability</h2>
        <p>
          If any provision of these Terms is held to be unenforceable or invalid,
          such provision will be changed and interpreted to accomplish the
          objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full force
          and effect.
        </p>
        <h2>Waiver</h2>
        <p>
          Except as provided herein, the failure to exercise a right or to require
          performance of an obligation under these Terms shall not effect a party's
          ability to exercise such right or require such performance at any time
          thereafter nor shall the waiver of a breach constitute a waiver of any
          subsequent breach.
        </p>
        <h1>Translation Interpretation</h1>
        <p>
          These Terms and Conditions may have been translated if We have made them
          available to You on our Service. You agree that the original English text
          shall prevail in the case of a dispute.
        </p>
        <h1>Changes to These Terms and Conditions</h1>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace these
          Terms at any time. If a revision is material We will make reasonable
          efforts to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at Our sole
          discretion.
        </p>
        <p>
          By continuing to access or use Our Service after those revisions become
          effective, You agree to be bound by the revised terms. If You do not agree
          to the new terms, in whole or in part, please stop using the website and
          the Service.
        </p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions about these Terms and Conditions, You can
          contact us:
        </p>
        <ul>
          <li>By email: support@litelyapp.com</li>
        </ul> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    let type = this.$route.params.type;
    if (type === "app") {
      this.$store.commit("setIsApp", true);
    } else {
      this.$store.commit("setIsApp", false);
    }
  },
  mounted() { },
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 70%;
  height: 100%;
  // background-color: rgba(248, 248, 248, 1);
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 60px;

  p {
    text-align: left !important;
    margin-top: 7px;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h2,
  h3 {
    margin-top: 10px;
    text-align: left;
  }

  h4,
  h5 {
    text-align: left;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    width: 90%;
  }
}
</style>
